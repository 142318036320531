// User Actions
export const LOGOUT = "LOGOUT";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_TEAM_MEMBERS_DATA = "SET_TEAM_MEMBERS_DATA";
export const SET_USER_LOADER = "SET_USER_LOADER";

// Snakbar Actions
export const SET_SUCCESS_SNACKBAR = "SET_SUCCESS_SNACKBAR";
export const SET_ERROR_SNACKBAR = "SET_ERROR_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";

// Contacts Acctions
export const SET_CONTACTS_DATA = "SET_CONTACTS_DATA";
export const SET_CONTACTS_LOADER = "SET_CONTACTS_LOADER";
export const SET_CONTACTS_LABELS = "SET_CONTACTS_LABELS";
export const SET_CONTACTS_FILTERS = "SET_CONTACTS_FILTERS";
export const CLEAR_CONTACTS_FILTERS = "CLEAR_CONTACTS_FILTERS";
export const SET_CONTACTS_DETAIL_DATA = "SET_CONTACTS_DETAIL_DATA";
//Template Builder Actions
export const SET_DYNAMIC_FIELD_VALUE = "SET_DYNAMIC_FIELD_VALUE";
export const SET_DYNAMIC_FIELDS = "SET_DYNAMIC_FIELDS";
export const UPDATE_DYNAMIC_FIELDS = "UPDATE_DYNAMIC_FIELDS";
export const UPDATE_CONTACT_FIELDS = "UPDATE_CONTACT_FIELDS";
export const REMOVE_FROM_DYNAMIC_FIELDS = "REMOVE_FROM_DYNAMIC_FIELDS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const CLEAR_DYNAMIC_FIELDS = "CLEAR_DYNAMIC_FIELDS";
export const CLEAR_FIELDS = "CLEAR_FIELDS";

// Templates Action
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const GET_ONE_TEMPLATE = 'GET_ONE_TEMPLATE';
export const TEMPLATE_LOADING = 'TEMPLATE_LOADING';
export const TEMPLATE_PAGINATION_CHANGE = 'TEMPLATE_PAGINATION_CHANGE';
export const TEMPLATE_SEARCH = 'TEMPLATE_SEARCH';
export const CLEAR_ALL_TEMPLATE = 'CLEAR_ALL_TEMPLATE';
export const CLEAR_TEMPLATE_FIELDS = 'CLEAR_TEMPLATE_FIELDS';
export const GET_DYNAMIC_FIELDS_FROM_SERVER = 'GET_DYNAMIC_FIELDS_FROM_SERVER';
export const LOAD_DATA_FROM_LOCAL_STORAGE = 'LOAD_DATA_FROM_LOCAL_STORAGE';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';

//Product
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const SELECT_POSTCARD = "SELECT_POSTCARD";

// Orders Actions
export const SET_ORDERS_DATA = "SET_ORDERS_DATA";
export const SET_ORDERS_LOADER = "SET_ORDERS_LOADER";
export const SET_ORDERS_FILTERS_LABELS = "SET_ORDERS_FILTERS_LABELS";
export const SET_ORDERS_FILTERS = "SET_ORDERS_FILTERS";
export const CLEAR_ORDERS_FILTERS = "CLEAR_ORDERS_FILTERS";
export const SET_ORDERS_DETAIL_CONTACTS_DATA =
  "SET_ORDERS_DETAIL_CONTACTS_DATA";
export const SET_ORDERS_DETAIL_OVERVIEW_DETAIL =
  "SET_ORDERS_DETAIL_OVERVIEW_DETAIL";
export const SET_ORDER_DETAIL_LOADER = "SET_ORDER_DETAIL_LOADER";
export const SET_ORDER_DETAIL_CONTACTS_LOADER =
  "SET_ORDER_DETAIL_CONTACTS_LOADER";
export const CONTACTS_SEARCH = "CONTACTS_SEARCH";

// Product Actions
export const SET_PRODUCT_TYPES = "SET_PRODUCT_TYPES";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_PRODUCTS_DATA = "SET_PRODUCTS_DATA";

// Api-Keys Actions
export const SET_API_KEYS_DATA = "SET_API_KEYS_DATA";
export const SET_API_KEYS_LOADER = "SET_API_KEYS_LOADER";

// CustomField Actions
export const SET_CUSTOM_FIELDS_DATA = "SET_CUSTOM_FIELDS_DATA";
export const SET_CUSTOM_FIELDS_LOADER = "SET_CUSTOM_FIELDS_LOADER";

// General Setting
export const SET_GENERAL_DATA = "SET_GENERAL_DATA";

//Stripe Actions
export const SET_STRIPE_PAYMENT_METHODS = "SET_STRIPE_PAYMENT_METHODS";
export const SET_STRIPE_INVOICES = "SET_STRIPE_INVOICES";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const SET_TRANSACTIONS_LOADER = "SET_TRANSACTIONS_LOADER";
export const SET_METHOD_LOADER = "SET_METHOD_LOADER";

// Tags Actions
export const SET_TAGS_DATA = "SET_TAGS_DATA";
export const SET_TAGS_LOADER = "SET_TAGS_LOADER";

// Webhooks Actions
export const SET_WEBHOOKS_DATA = "SET_WEBHOOKS_DATA";
export const SET_WEBHOOKS_EVENTS_DATA = "SET_WEBHOOKS_EVENTS_DATA";
export const SET_WEBHOOKS_LOADER = "SET_WEBHOOKS_LOADER";

// Dashboard
export const SET_DASHBOARD_WIDGETS_SPENDINGS = "SET_DASHBOARD_WIDGETS_SPENDINGS";
export const SET_DASHBOARD_WIDGETS_DATA = "SET_DASHBOARD_WIDGETS_DATA";
export const SET_DASHBOARD_CHART_DATA = "SET_DASHBOARD_CHART_DATA";
export const SET_LIMIT_MODAL = "SET_LIMIT_MODAL";
export const SET_DYNAMIC_LIMIT_MODAL = "SET_DYNAMIC_LIMIT_MODAL";

// GENERAL SETTINGS
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';

// ADMIN DASHBOARD ACTIONS //

// Admin Users Actions
export const SET_ADMIN_USERS_DATA = "SET_ADMIN_USERS_DATA";
export const SET_ADMIN_USERS_LOADER = "SET_ADMIN_USERS_LOADER";
export const SET_ADMIN_USERS_FILTERS_LABELS = "SET_ADMIN_USERS_FILTERS_LABELS";
export const SET_ADMIN_USERS_FILTERS = "SET_ADMIN_USERS_FILTERS";
export const CLEAR_ADMIN_USERS_FILTERS = "CLEAR_ADMIN_USERS_FILTERS";

// Admin Batches Actions
export const SET_ADMIN_BATCHES_DATA = "SET_ADMIN_BATCHES_DATA";
export const SET_ADMIN_BATCHES_LOADER = "SET_ADMIN_BATCHES_LOADER";
export const SET_ADMIN_BATCHES_FILTERS_LABELS =
  "SET_ADMIN_BATCHES_FILTERS_LABELS";
export const SET_ADMIN_BATCHES_FILTERS = "SET_ADMIN_BATCHES_FILTERS";
export const CLEAR_ADMIN_BATCHES_FILTERS = "CLEAR_ADMIN_BATCHES_FILTERS";

// Admin Orders Actions
export const SET_ADMIN_ORDERS_DATA = "SET_ADMIN_ORDERS_DATA";
export const SET_ADMIN_ORDERS_LOADER = "SET_ADMIN_ORDERS_LOADER";
export const SET_ADMIN_ORDER_DISPATCH_LOADER = "SET_ADMIN_ORDER_DISPATCH_LOADER";
export const SET_ADMIN_ORDERS_FILTERS_LABELS =
  "SET_ADMIN_ORDERS_FILTERS_LABELS";
export const SET_ADMIN_ORDERS_FILTERS = "SET_ADMIN_ORDERS_FILTERS";
export const CLEAR_ADMIN_ORDERS_FILTERS = "CLEAR_ADMIN_ORDERS_FILTERS";

// Admin Dashboaard Actions
export const SET_ADMIN_DASHBOARD_CHART_DATA = 'SET_ADMIN_DASHBOARD_CHART_DATA';

// Admin Template Actions
export const SET_ADMIN_TEMPLATE_CATEGORIES_DATA = 'SET_ADMIN_TEMPLATE_CATEGORIES_DATA';
export const SET_ADMIN_TEMPLATE_CATEGORIES_LOADER = 'SET_ADMIN_TEMPLATE_CATEGORIES_LOADER';




// Sources Actions
export const SET_SOURCES = "SET_SOURCES";
export const SET_SOURCES_LOADER = "SET_SOURCES_LOADER";
